import { rest } from 'msw';
import postGameGameTableOverview from '@features/widget-series-table/fixtures/data-new.json';

const pagesFixtures = [
    {
        totalCount: 6,
        pageInfo: {
            hasPreviousPage: false,
            hasNextPage: true,
        },
        nodes: ['game-id-1', 'game-id-2', 'game-id-3'],
    },
    {
        totalCount: 6,
        pageInfo: {
            hasPreviousPage: true,
            hasNextPage: false,
        },
        nodes: ['game-id-4', 'game-id-5', 'game-id-6'],
    },
];

const detailedGamesFixtures = {
    'game-id-1': {
        id: 'game-id-1',
        startedAt: '2024-06-05T10:07:36.253Z',
        finished: true,
        finishedAt: '2024-06-05T10:07:36.253Z',
        duration: 'PT21M5S',
        map: {
            id: 'map-bank-v2',
        },
        teams: [
            {
                id: 'team-id-1',
                side: 'ATTACK',
                won: true,
                score: 13,
                players: [
                    {
                        id: 'player-1',
                        name: 'Player One',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-2',
                        name: 'Player Two',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-3',
                        name: 'Player Three',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-4',
                        name: 'Player Four',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
            {
                id: 'team-id-2',
                side: 'DEFENSE',
                won: false,
                score: 7,
                players: [
                    {
                        id: 'player-5',
                        name: 'Player Five',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-6',
                        name: 'Player Six',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-7',
                        name: 'Player Seven',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-8',
                        name: 'Player Eight',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
        ],
    },
    'game-id-2': {
        id: 'game-id-2',
        startedAt: '2024-06-05T10:07:36.253Z',
        finished: true,
        finishedAt: '2024-06-05T10:07:36.253Z',
        duration: 'PT21M5S',
        map: {
            id: 'map-consulate-id',
        },
        teams: [
            {
                id: 'team-id-1',
                side: 'ATTACK',
                won: true,
                score: 13,
                players: [
                    {
                        id: 'player-1',
                        name: 'Player One',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-2',
                        name: 'Player Two',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-3',
                        name: 'Player Three',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-4',
                        name: 'Player Four',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
            {
                id: 'team-id-2',
                side: 'DEFENSE',
                won: false,
                score: 7,
                players: [
                    {
                        id: 'player-5',
                        name: 'Player Five',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-6',
                        name: 'Player Six',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-7',
                        name: 'Player Seven',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-8',
                        name: 'Player Eight',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
        ],
    },
    'game-id-3': {
        id: 'game-id-3',
        startedAt: '2024-06-05T10:07:36.253Z',
        finished: true,
        finishedAt: '2024-06-05T10:07:36.253Z',
        duration: 'PT21M5S',
        map: {
            id: 'map-russian-cafe-id',
        },
        teams: [
            {
                id: 'team-id-1',
                side: 'ATTACK',
                won: true,
                score: 13,
                players: [
                    {
                        id: 'player-1',
                        name: 'Player One',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-2',
                        name: 'Player Two',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-3',
                        name: 'Player Three',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-4',
                        name: 'Player Four',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
            {
                id: 'team-id-2',
                side: 'DEFENSE',
                won: false,
                score: 7,
                players: [
                    {
                        id: 'player-5',
                        name: 'Player Five',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-6',
                        name: 'Player Six',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-7',
                        name: 'Player Seven',
                        character: { id: '0x3d42207d45' },
                    },
                    {
                        id: 'player-8',
                        name: 'Player Eight',
                        character: { id: '0x3d42207d45' },
                    },
                ],
            },
        ],
    },
};

const getPostGameGameIds = rest.get(
    'http://localhost:3002/post-game-api/titles/:titleId/gameIds',
    (req, res, ctx) => {
        const url = new URL(req.url);
        const page = Number(url.searchParams.get('page'));

        if (page) {
            const pageNumber = Number(page);
            const responsePage = pagesFixtures[pageNumber - 1];

            return res(ctx.json(responsePage));
        }

        return res(ctx.json(pagesFixtures[0]));
    },
);

const getPostGameGameDetail = rest.get(
    'http://localhost:3002/post-game-api/titles/:titleId/games/:gameId',
    (req, res, ctx) => {
        if (req.params.gameId) {
            const foundDetails = detailedGamesFixtures[String(req.params.gameId)];

            if (foundDetails) {
                return res(ctx.json(foundDetails));
            }
        }
        return res(
            ctx.status(400),
            ctx.json({
                timestamp: '2025-01-09T15:39:58.963+00:00',
                status: 400,
                error: 'Bad Request',
                path: '/titles/25/games/eb815494-784a-462e-x9e84-d62f3ad4718f',
            }),
        );
    },
);

const getPostGameGameTableOverview = rest.get(
    'http://localhost:3002/post-game-api-gateway/titles/:titleId/games/:gameId',
    (req, res, ctx) =>
        res(
            ctx.json(
                postGameGameTableOverview.stateGroups.filter(
                    (stateGroup) => stateGroup.name === 'Game',
                ),
            ),
        ),
);

export default [getPostGameGameIds, getPostGameGameDetail, getPostGameGameTableOverview];
