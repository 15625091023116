import organisations from './organisations';
import predictions from './predictions';
import users from './users';
import fileDownloads from './fileDownloads';
import myProfile from './profile';
import postGameApi from './postGameApi';

export default [
    ...organisations,
    ...predictions,
    ...users,
    ...fileDownloads,
    ...myProfile,
    ...postGameApi,
];
